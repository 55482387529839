<template>
  <b-card title="Thông tin sản phẩm">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tên sản phẩm"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-first-name"
              v-model="productData.name"
              placeholder="Bưởi hồng da xanh"
              :class="{ 'is-invalid' : isInvalid(productData.name)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Mô tả"
            label-cols-md="2"
            label-for="product-description"
          >
            <b-form-textarea
              id="product-description"
              v-model="productData.description"
              placeholder=""
              rows="5"
              :class="{ 'is-invalid' : isInvalid(productData.description)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Giá (VNĐ)"
            label-cols-md="2"
            label-for="h-price"
          >
            <b-form-input
              id="h-price"
              v-model="productData.price"
              placeholder="100000"
              type="number"
              :class="{ 'is-invalid' : isInvalid(productData.price)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Đơn vị"
            label-cols-md="2"
            label-for="h-unit"
          >
            <b-form-select
              v-model="productData.unit"
              :options="options"
              class="mt-1"
              size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Danh mục"
            label-cols-md="2"
            label-for="h-category"
          >
            <b-form-select
              v-model="productData.category_id"
              :options="categoryOptions"
              class="mt-1"
              size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Link mua hàng"
            label-cols-md="2"
            label-for="h-product-link"
          >
            <b-form-input
              id="h-product-link"
              v-model="productData.product_link"
              placeholder="https://shopee.com/*****"
              :class="{ 'is-invalid' : isInvalid(productData.product_link)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hiển thị sản phẩm trên trang chủ"
            label-cols-md="2"
            label-for="h-banner"
          >
            <b-form-checkbox
              id="h-banner"
              v-model="productData.is_feature_product"
              name="checkbox-2"
            >
              {{ productData.is_feature_product ? 'Có hiển thị' : 'Không hiển thị'}}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hình ảnh đại diện"
            label-cols-md="2"
            label-for="h-images"
          >
            <b-form-input
              id="product-avatar"
              v-model="productData.avatar"
              placeholder=""
              rows="5"
              :class="{ 'is-invalid' : isInvalid(productData.avatar)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="productData.avatar">
          <b-form-group
            label="Xem trước hình ảnh"
            label-cols-md="2"
            label-for="h-image-preview "
          >
            <b-img class="preview-image" :src="productData.avatar" v-viewer />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hình ảnh"
            label-cols-md="2"
            label-for="h-images"
          >
            <b-form-textarea
              id="post-summary"
              v-model="productData.images"
              placeholder=""
              rows="5"
              :class="{ 'is-invalid' : isInvalid(productData.images)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="productData.images">
          <b-form-group
            label="Xem trước hình ảnh"
            label-cols-md="2"
            label-for="h-image-preview "
            img-width="200"
            img-height="200"
          >
            <b-carousel
              id="carousel-example-generic"
              controls
              fades
              indicators
              :interval="3000"
            >
              <b-carousel-slide v-for="(image, index) in productData.images.split('\n')"
                                :key="image + '-' + index"
                                :img-src="image"
                                v-viewer
              />
            </b-carousel>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1"
          type="submit"
          variant="primary"
        >
          Lưu sản phẩm
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import router from '@/router'
import {
  BButton,
  BCard,
  BCarousel,
  BCarouselSlide,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BImg,
  BRow
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "../../../../@core/components/toastification/ToastificationContent";

export default {
  name: "ProductEdit",
  computed: {
    categoryOptions() {
      return this.categories.map((e) => {
        return {
          value: e.id,
          text: e.name
        }
      })
    }
  },
  components: {
    BFormTextarea,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCarousel,
    BCarouselSlide,
    BImg
  },
  directives: {
    Ripple
  },
  data() {
    return {
      isValidate: false,
      files: [],
      nksx: '',
      productData: {},
      categories: [],
      options: [
        {value: 'kg', text: 'Kg'},
        {value: 'túi', text: 'Túi'},
        {value: 'nải', text: 'Nải'},
        {value: 'quả', text: 'Quả'},
      ],
    }
  },
  methods: {
    isInvalid(val) {
      return this.isValidate && val.length === 0
    },
    removeImage(image, index) {
      this.productData.images.splice(index, 1)
    },
    handleSubmit() {
      this.isValidate = true
      if (
          this.productData.name.length &&
          this.productData.description.length &&
          this.productData.price.length &&
          this.productData.product_link.length &&
          this.productData.avatar.length &&
          this.productData.images.length) {
        this.handleRequest()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    },
    async handleRequest() {
      let id = router.currentRoute.params.id;
      this.nksx.summary = ''
      this.productData.nksx = JSON.stringify(this.nksx)
      this.$http.put(`/products/${id}`, this.productData)
        .then(response => {
          let product = response.data.data
          product.nksx = '{}'
          this.nksx.summary = JSON.stringify(product)
          this.nksx.category_id = product.category_id
          this.updateNKSX(this.nksx)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    },
    async updateNKSX(nksx) {
      let id = nksx.id;
      this.$http.put(`/posts/${id}`, nksx)
          .then(response => {
            console.log(response.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Cập nhật thành công👋',
                variant: 'success',
              },
            })
            this.$router.push('/manage/product/list')
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                variant: 'danger',
              },
            })
          })
    }
  },
  async created() {
    let id = router.currentRoute.params.id;
    const response = await this.$http.get(`/products/${id}`);
    if (response.status === 200) {
      this.productData = response.data.data
      this.productData.is_feature_product = this.productData.is_feature_product === '1'

      const nksx_id = JSON.parse(this.productData.nksx).id
      const response2 = await this.$http.get(`/posts/${nksx_id}`);
      if (response2.status === 200) {
        this.nksx = response2.data.data
      }
    }
    this.$http.get('/search/category?page_id=SP')
      .then(res => {
        this.categories = res.data.data
      })
  }
}
</script>

<style scoped>

</style>
